.editclosebutton {
    background-color: #044cac;
    color: #FBFAF5;
    text-transform: none;
}

.line-by {
    display: flex;
    position: absolute;
    /* color: grey; */

}

.input-lineBy {
    margin-right: 55px;
    border-radius: 5px;
    color: #616168
}

.input-lineBy:focus {
    outline: none;
    /* background-color: red; */
}

.input-lineBy:hover {
    cursor: default;
    /* background-color: red; */
}

label {
    font-weight: 500;
    /* color: gray; */
}

.more-btn {
    background-color: #044cac;
    color: white;
    width: 90px;
    border-radius: 5px;
}