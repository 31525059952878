#add__issue {
    margin: 10px;
    padding: 5px 10px;
    font-size: 17px;
    justify-self: center;
    background-color: #044cac;
    color: white;
    text-transform: none;
    width: 200px;
}

.btn_class {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}