.box {
    display: flex;
    /* flex: 0.2; */
}

.box1 {
    /* border: solid; */
    min-height: 100vh;
    /* max-width: 280px; */
    flex: 0.18;
    background-color: #004AAD;
    flex-direction: column;
    color: white;
    display: flex;
    justify-content: space-between;
    height: 10px;
    align-items: baseline;
    position: sticky;
    top: 0;

}

.box2 {
    /* border: solid; */
    flex: 0.80;
    background-color: white;
    display: flex;
}

.issuebox {
    /* border: solid 1px; */
    flex: 0.50;
    /* background-color: red; */
}

.commentbox {
    /* border: solid 1px; */
    flex: 0.50;

}

.hit:nth-child(2) {
    box-sizing: border-box;

    position: absolute;
    left: 22.29%;
    right: 60.56%;
    top: 16.33%;
    bottom: 78.78%;

    background: #FFFFFF;
    /* lines, borders */

    border: 1px solid #D4D5DF;
    border-radius: 5px;
}

.dashboard {
    /* border: solid; */
    display: flex;
    justify-content: space-around;
    border-radius: 10px;
    margin: 20px;
}


.small_box {
    display: flex;
    /* background-color: blue; */
    justify-content: space-between;
    flex: 0.2
}

.box1 img {
    height: 100px;
    width: 200px;
    /* margin-top: 20px; */
    margin: 10px auto;

    /* margin: 0; */
    /* box-shadow: 1px 4px 10px black; */
}

.small_box div:nth-child(1) {
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(212, 34, 66, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    height: 70px;

}


.addbutt {
    position: absolute;
    left: 90%;
    z-index: 100;
}

.small_box div:nth-child(2) {
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(253, 156, 12, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    height: 70px;

}

/* .MuiInputBase-adornedEnd {
    margin-right: 325px;
    background: gray;
    color: white;
} */



.small_box div:nth-child(3) {
    margin: 10px;
    /* border: solid 1px; */
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(142, 228, 140, 0.8);
    /* box-shadow: 1px 2px 1px 2px grey; */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    height: 70px;


}

.small_box>div>h5 {
    font-weight: 600;
    font-size: 32px;
    text-align: center;
    margin-top: -5px;
}

.small_box>div>p {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    margin-top: -10px;
}

.small_box span {
    font-weight: 800;
}

td {
    border: 1px solid lightgray !important;
    /* outline: 1px solid lightgray !important; */
}

th {
    border: 1px solid lightgray !important;
    /* outline: 1px solid lightgray !important; */
}

th:nth-last-child(1) {
    border: 1px solid lightgray !important;
    outline: 1px solid lightgray !important;
}

thead {
    border: 1px solid lightgray !important;
    /* outline: 1px solid lightgray !important; */
}

tr {
    border: 1px solid lightgray !important;
    /* outline: 1px solid lightgray !important; */
}

.MuiToolbar-root {
    margin-bottom: 100px !important;
}

.addissuebutton {
    width: 150px;
    align-self: center;
    margin: 3% 2%;
    /* background-color: #002960; */
    color: white;
    font-size: 17px;
    font-weight: 300;
    padding: 7px;
    background: linear-gradient(180deg, #004AAD 0%, #002960 100%);
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    float: right;
}

.sidenavbuttons {
    border: none;
    outline: none;
    background-color: blue;
    color: white;
    margin-left: 30px;
    font-size: 20px;
    font-weight: bold;
    width: 200px;
    margin: 100px;
}

/* .navlink {} */

.dashboardTitle {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    color: #033374;
    padding: 20px;
    flex: 0.8;
    vertical-align: middle;
}

.addissueinputfield {
    color: rgb(139, 139, 139) !important;
    font-family: 'Jost' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
}

.addissueinputfield::placeholder {
    color: #8B8B8B !important;
    font-family: 'Jost' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
}

.issuetableviewmore {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 14px;
}

.butt:disabled {
    color: black;
    background-color: #8B8B8B !important;
}

/* input boxes */
.inputbox {
    /* background-color: red; */
    margin-top: -0.5rem;
    margin-left: 1rem;
}

.inputbox1 {
    display: flex;
    /* border: solid; */
    justify-content: space-evenly;
    /* background-color: red; */
    margin-bottom: 2rem;
    margin-top: 1rem;

}

.inputbox2 {
    display: flex;
    /* border: solid; */
    justify-content: space-evenly;
    /* background-color: red; */
    margin-bottom: 2rem;

}

.inputbox3 {
    display: flex;
    /* border: solid; */
    justify-content: space-evenly;
    /* background-color: red; */
    margin-bottom: 3rem;

}

.descriptionbox {
    margin-left: 1.6rem;
    margin-bottom: 3rem;
    /* background-color: red; */


}

.file {
    margin-left: 1.5rem;
    margin-top: 2rem;
    /* padding-bottom: 1rem; */
}

.filebutt {
    margin-left: 9.2rem;
    display: flex;
    justify-content: space-between;
    width: 37%;
    margin-bottom: 1rem;
}

.filebutt button {
    margin-left: 10px;

    background-color: #044cac;
    color: white;
    width: 90px;
    border-radius: 5px;
    margin-top: .5rem;
}

/* @media (max-width:1200px) {
    .issuebox {

        flex: 0.50;
        background-color: red;
    }

} */