article {
    height: 100vh;
    width: 100vw;
    background: rgb(4, 76, 172);
    color: #fff;
    font-family: Open Sans;
    font-weight: 100;
    font-size: 20px;
    color: #fff;
    text-align: center;
    justify-content: center;
    align-items: center;
}

article>a {
    color: #fff;
    font-weight: bold;
}

article>a:hover {
    text-decoration: none;
}

article>img {
    /* width     : 75px; */
    margin-top: 1em;
}