.box {
    display: flex;
    /* border: solid; */
    height: 100vh;
    overflow: hidden !important;
}

.box1 {
    /* border: solid; */
    height: 100vh;
    background-color: #044cac;
    flex: 0.7;
    background-image: url('../../assets/Frame\ 345.png');
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;

}

.box2 {
    background-color: rgb(252, 252, 252);
    height: 735px;
    /* border: solid; */
    flex: 0.3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.curve_img img {
    width: 500px;
    height: 150px;
}

.head {
    /* border: solid; */
    margin-top: 30%;
    margin-left: 27%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* align-items: center; */
    height: 100px;
    color: white;

}

.head img {
    height: 350px;
    width: 350px;
}

.head p {
    font-size: 1.3rem;
    margin-left: 15rem;
    margin-top: -1.2rem;
    font-weight: 400;
}

.form__heading {
    /* background-color: red; */
    /* margin-left: px; */
    margin-top: 30px;

}

.form__heading h3 {
    color: #033374;
    font-weight: bold;
    font-size: 30px;
}

.form__heading p {
    font-size: 16px;
    margin-top: -5px;
    margin-left: 5px;
}

.form_container {
    margin: 10% auto;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: red; */
    height: 250px;
    /* color: rgba(239, 236, 236, 0.959); */

}

.button1 {
    margin-top: 10%;
    width: 150px;
    align-self: center;
    /* background-color: #002960; */
    color: white;
    font-size: 17px;
    font-weight: 300;
    padding: 7px;
    background: linear-gradient(180deg, #004AAD 0%, #002960 100%);
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
}

.sirpi_background_image {
    background-image: url('../../assets/Group\ 113.png');
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
}

.sirpi img {
    height: 10rem;
    margin-bottom: 50px;

}

@media (max-height:650px) {

    .sirpi img {
        /* background-color: red; */
        height: 8rem;
        margin-bottom: 150px;
        margin-top: -10px;
    }

}