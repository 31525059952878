.butt {
    background-color: lightgreen !important;
    margin-left: 64rem;
    color: white;
    position: relative;
    bottom: 2.7rem;
    z-index: 100;
    left: 10vw;

    /* bottom: 30% */
}