.box {
    border: solid;
    border: 1px solid #181A18;
    border-radius: 10px;
    margin: 10px;
    /* color: rgba(128, 128, 128, 0.304); */
}

.box h5 {
    border-bottom: solid 1px;
    margin-bottom: 10px;
    /* margin-left: 10px; */

}

.box p {
    padding: 10px;
    color: #616168;
}