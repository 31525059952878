@import url('https://fonts.googleapis.com/css2?family=Jost&family=Mulish&family=Poppins&display=swap');

* {
    font-family: Jost;
}

/* .MTableToolbar-title-9 {
    overflow: visible !important;
}

.MuiIconButton-label {
    width: 85% !important;
} */