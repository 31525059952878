.box {
    display: flex;
    /* flex: 0.2; */
}

.tab span {
    display: flex;
    min-width: 1px !important;
    /* width: 30px; */
}


.box1 {
    /* border: solid; */
    min-height: 100vh;
    /* max-width: 280px; */
    flex: 0.18;
    background-color: #004AAD;
    flex-direction: column;
    color: white;
    display: flex;
    justify-content: space-between;
    height: 10px;
    align-items: baseline;
    position: sticky;
    top: 0;
}

.box2 {
    /* border: solid; */
    display: flex;
    flex: 0.90;
    background-color: white;
    /* border: solid; */
    flex-direction: column;
    /* align-items: center; */
}

.first {
    display: flex;
    padding: 20px;
    padding-right: 10px;
    justify-content: center;
    color: white;
    background: #005AA7;
    background: -webkit-linear-gradient(to top, #e1e2ee, #005AA7);
    background: linear-gradient(to top, #e2e2e9, #005AA7);

}

.sec {
    /* border: solid; */
    display: flex;
}

.sec p {
    /* border: solid; */
    font-size: 1.5rem;
    margin-top: 10rem;
    margin-left: 2rem;
    text-align: center;
}

.foot {
    /* border: solid; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.foot img {
    height: 7vh;
    width: 9vw;
}