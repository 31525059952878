  @import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;800&display=swap');

  body {
    /* max-height: 726px; */

    margin: 0;
    font-family: 'Mulish', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #FBFAF5;
    /* background: #181A18; */
  }

  .MuiSvgIcon-root {
    color: rgb(117, 117, 118) !important;
  }

  .MuiTableSortLabel-icon {
    opacity: 1 !important;
    /* background-image: url(); */
  }

  .MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-5.MuiToolbar-gutters {
    display: flex;
    padding: 0;

    padding-bottom: 20px;
    padding-left: 2rem;


  }



  .MuiTypography-root.MuiIconButton-label {
    width: 85% !important;
  }

  /* .MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-5.MuiToolbar-gutters span {
    display: flex;
  } */

  .MuiTypography-root.MuiTypography-h6 {
    font-weight: 100 !important;
    font-size: 20px !important;
    overflow: visible !important;
    margin-right: 50px;
  }

  /* .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit {
    width: 200px;
  } */

  /* .MuiTypography-root.MTableToolbar-title-9 {
      overflow: visible !important;
    } */

  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd {
    border: 2px solid #C4C4C4;
    background-color: rgba(85, 80, 80, 0.068);
    border-radius: 5px;
    padding: 3px 5px;
    text-decoration: none;
  }

  .MuiTypography-root.MuiTypography-h6 {
    font-weight: 600;
    font-size: 22px;
    color: #3b3939;
  }

  /* .MuiFormControl-root.MuiTextField-root.MTableToolbar-searchField-10 {
    min-width: 17rem !important;
    margin-right: 30px;
  } */

  /* .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit {
    margin-left: 0;
  } */

  /* .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    color: white !important;
  } */

  .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft {
    display: table-cell;
    padding: 10px;
    font-size: 0.875rem;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.2;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
  }

  .Toastify__toast-body {
    font-weight: 600;
    color: black;
  }

  /* .MuiTableCell-root.MuiTableCell-head.jss17.MuiTableCell-paddingCheckbox {
    padding-left: 16px !important;
  } */
  .MuiTableCell-root.MuiTableCell-head.MTableHeader-header-17.MuiTableCell-paddingCheckbox {
    padding-left: 17px;
  }