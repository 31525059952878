.box {
    display: flex;
    /* flex: 0.2; */
}

.tab span {
    display: flex;
    min-width: 1px !important;
    /* width: 30px; */
}


.box1 {
    /* border: solid; */
    min-height: 100vh;
    /* max-width: 280px; */
    flex: 0.18;
    background-color: #004AAD;
    flex-direction: column;
    color: white;
    display: flex;
    justify-content: space-between;
    height: 10px;
    align-items: baseline;
    position: sticky;
    top: 0;
}

.box2 {
    /* border: solid; */
    flex: 0.80;
    background-color: white;
}

.dashboard {
    /* border: solid; */
    display: flex;
    justify-content: space-around;
    border-radius: 10px;
    margin: 20px;
}


.small_box {
    display: flex;
    /* background-color: blue; */
    justify-content: space-between;
    flex: 0.2;
    font-size: medium;
}

.box1 img {
    height: 100px;
    width: 200px;
    /* margin-top: 20px; */
    margin: 10px auto;

    /* margin: 0; */
    /* box-shadow: 1px 4px 10px black; */
}

.small_box div:nth-child(1) {
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: #FE0000;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    height: 70px;

}

.addbutt {
    position: absolute;
    left: 90%;
    z-index: 100;
}

.backto {
    background: #a8ff78;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #78ffd6, #a8ff78);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #78ffd6, #a8ff78);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    font-weight: 600;
    color: rgb(56, 53, 53);
    border-radius: 10px;
    /* -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent; */
}

.small_box div:nth-child(2) {
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: #FF8A00;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    height: 70px;

}

/* .MuiInputBase-adornedEnd {
    margin-right: 325px;
    background: gray;
    color: white;
} */



.small_box div:nth-child(3) {
    margin: 10px;
    /* border: solid 1px; */
    padding: 10px;
    border-radius: 10px;
    background-color: #FEE500;
    /* box-shadow: 1px 2px 1px 2px grey; */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    height: 70px;
    /* overflow; */


}

.small_box>div>h5 {
    font-weight: 600;
    font-size: 32px;
    text-align: center;
    margin-top: -5px;
}

.small_box>div>p {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    margin-top: -10px;
}

.small_box span {
    font-weight: 800;
}

td {
    border: 1px solid lightgray !important;
    /* outline: 1px solid lightgray !important; */
}

th {
    border: 1px solid lightgray !important;
    /* outline: 1px solid lightgray !important; */
}

th:nth-last-child(1) {
    border: 1px solid lightgray !important;
    outline: 1px solid lightgray !important;
}

thead {
    border: 1px solid lightgray !important;
    /* outline: 1px solid lightgray !important; */
}

tr {
    border: 1px solid lightgray !important;
    /* outline: 1px solid lightgray !important; */
}

.MuiToolbar-root {
    margin-bottom: 100px !important;
}

/* .forminput> {
    color: black;
    background-color: red;
} */

.addissuebutton {
    width: 150px;
    align-self: center;
    margin: 3% 2%;
    /* background-color: #002960; */
    color: white;
    font-size: 17px;
    font-weight: 300;
    padding: 7px;
    background: linear-gradient(180deg, #004AAD 0%, #002960 100%);
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    float: right;
}

.addiss {
    width: 150px;
    align-self: center;
    margin: 3% 2%;
    /* background-color: #002960; */
    color: white;
    font-size: 17px;
    font-weight: 300;
    padding: 7px;
    background: linear-gradient(180deg, #09428d 0%, #2f71c8 100%);
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    float: right;
}

.addiss:hover {
    text-decoration: none;
    color: white;
}

.sidenavbut {
    outline: none;
    padding: 0.7rem;
    background-color: #003C8B;
    color: white;
    font-size: 1.1rem;
    width: 280px;
    display: flex;
    margin-top: 1rem;
    /* line-height: 28px; */
    text-decoration: none;
    text-align: center;
    padding-left: 2rem;

}

.sidenavbuttons span {
    padding-left: 15px;
}

.dashboardTitle {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    color: #033374;
    padding: 20px;
    flex: 0.8;
    vertical-align: middle;
}

.addissueinputfield {
    color: rgb(139, 139, 139) !important;
    font-family: 'Jost' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
}

.addissueinputfield::placeholder {
    color: #8B8B8B !important;
    font-family: 'Jost' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
}

.issuetableviewmore {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 14px;
}

.butt:disabled {
    color: black;
    background-color: #8B8B8B !important;
    background: #8B8B8B !important;
}

/* .butt:active {
    background-color: #002960;
} */



.nablink a:active,
.nablink a.active {
    background-color: rgb(8, 25, 99);
    /* border: none; */
    color: white;
}

.nablink a:hover {
    cursor: pointer;
    /* background-color: aliceblue; */
    /* color: #004AAD; */
    color: white;


}

.bug-btn {
    background-color: #004AAD;
    color: wheat;
}

/* @media screen and {} */
@media (max-height:700px) {

    .nablink {
        margin-top: 15rem !important;

    }

    .logoutbox {
        margin-top: 3rem;
    }

}

@media (max-height:400px) {


    .nablink {
        margin-top: 15rem !important;

    }

    .logoutbox {
        margin-top: 3rem;
    }

}